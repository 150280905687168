<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <h2 class="brand-text text-primary">
          회원가입
        </h2>

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="register"
          >
            <!-- username -->
            <b-form-group
              label="담당자명을 입력하세요."
              label-for="username"
            >
              <validation-provider
                #default="{ errors }"
                name="담당자명"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="username"
                  :state="errors.length > 0 ? false:null"
                  name="register-username"
                  placeholder="홍길동"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group
              label="이메일 주소를 입력하세요."
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="이메일"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="register-email"
                  placeholder="projectx@totalsolution.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
              label="비밀번호를 입력하세요"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="비밀번호"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="특수문자를 포함한 영문 8자 이상"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- phone number -->
            <b-form-group
              label="연락처를 입력하세요."
              label-for="phone"
            >
              <validation-provider
                #default="{ errors }"
                name="연락처"
                rules="required|integer"
              >
                <b-form-input
                  id="phone"
                  v-model="mobile"
                  :state="errors.length > 0 ? false:null"
                  name="register-email"
                  placeholder="0000000000"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group v-show="false">
              <b-form-checkbox
                id="register-privacy-policy"
                v-model="status"
                name="checkbox-1"
              >
                I agree to
                <b-link>privacy policy & terms</b-link>
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              회원가입하기
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" />
            로그인 바로가기
          </b-link>
        </b-card-text>

        <!-- modal -->
        <b-modal
          id="modal-danger"
          v-model="errorModal"
          ok-only
          ok-variant="danger"
          ok-title="확인"
          centered
          hide-header-close
          title="회원 가입 실패"
        >
          <b-card-text>
            {{ errorMessage }}
          </b-card-text>
        </b-modal>

        <!-- modal -->
        <b-modal
          id="modal-danger"
          v-model="okModal"
          ok-only
          ok-variant="primary"
          ok-title="확인"
          @ok="$router.replace({ name: 'auth-login' })"
          centered
          hide-header-close
          title="회원 가입이 완료되었습니다."
        >
          <b-card-text>
            {{ userEmail }} 계정으로 가입이 완료되었습니다.<br>
            로그인해주시기 바랍니다.
          </b-card-text>
        </b-modal>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import usePassword from '@/views/utils/usePassword'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    // BSV
    BCard,
    BLink,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      username: '',
      password: '',
      mobile: '',
      status: '',
      errorModal: false,
      okModal: false,
      errorMessage: '',
    }
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          useJwt.register({
            username: this.userEmail,
            name: this.username,
            mobile: this.mobile,
            password: this.password,
          }).then(response => {
            if (response.data.status === 2000) {
              this.$store.dispatch('auth/onSignUp', this.userEmail)
              this.okModal = true
            } else if (response.data.status === 4000) {
              this.errorMessage = response.data.payload[0].msg
              this.errorModal = true
            }
          }).catch(() => {
            this.errorMessage = '알 수 없는 오류가 발생하였습니다. 다시 시도해 주세요.'
            this.errorModal = true
          })
        }
      })
    },
  },
  setup() {
    const {
      passwordFieldType,
      passwordToggleIcon,
      togglePasswordVisibility,
    } = usePassword()

    return {
      passwordFieldType,
      passwordToggleIcon,
      togglePasswordVisibility,

      // validation rules
      required,
      email,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';
input[type=password] {font-family:'Malgun gothic', dotum, sans-serif;}
.modal-header {
  border-bottom: 0 none;
}

.modal-footer {
  border-top: 0 none;
}
</style>
