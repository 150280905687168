var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('h2',{staticClass:"brand-text text-primary"},[_vm._v(" 회원가입 ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('b-form-group',{attrs:{"label":"담당자명을 입력하세요.","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"담당자명","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 ? false:null,"name":"register-username","placeholder":"홍길동"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"이메일 주소를 입력하세요.","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"이메일","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"register-email","placeholder":"projectx@totalsolution.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"비밀번호를 입력하세요","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"비밀번호","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"특수문자를 포함한 영문 8자 이상"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"연락처를 입력하세요.","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"연락처","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false:null,"name":"register-email","placeholder":"0000000000"},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" I agree to "),_c('b-link',[_vm._v("privacy policy & terms")])],1)],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"}},[_vm._v(" 회원가입하기 ")])],1)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" 로그인 바로가기 ")],1)],1),_c('b-modal',{attrs:{"id":"modal-danger","ok-only":"","ok-variant":"danger","ok-title":"확인","centered":"","hide-header-close":"","title":"회원 가입 실패"},model:{value:(_vm.errorModal),callback:function ($$v) {_vm.errorModal=$$v},expression:"errorModal"}},[_c('b-card-text',[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1),_c('b-modal',{attrs:{"id":"modal-danger","ok-only":"","ok-variant":"primary","ok-title":"확인","centered":"","hide-header-close":"","title":"회원 가입이 완료되었습니다."},on:{"ok":function($event){return _vm.$router.replace({ name: 'auth-login' })}},model:{value:(_vm.okModal),callback:function ($$v) {_vm.okModal=$$v},expression:"okModal"}},[_c('b-card-text',[_vm._v(" "+_vm._s(_vm.userEmail)+" 계정으로 가입이 완료되었습니다."),_c('br'),_vm._v(" 로그인해주시기 바랍니다. ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }